
  import { Component, Vue, Prop, VModel } from "vue-property-decorator";

  @Component
  export default class LayoutSelect extends Vue {
    @Prop({ type: String })
    name!: string;

    @Prop({ type: Array })
    options!: { value: string; label: string }[];

    @Prop({ type: String })
    optionClass!: string;

    @VModel({ type: String })
    modelValue!: string;

    locale = this.$i18n.locale.split("-")[0];

    handleSelect(e) {
      this.$emit("input", e.target.value);
    }
  }
