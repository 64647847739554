
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzTable from "@/components/Table.vue";
  import LzModal from "@/components/Modal.vue";
  import DesignModal from "../components/DesignModal.vue";
  import { apiProjects } from "../api";
  import { namespace } from "vuex-class";
  import VueI18n from "vue-i18n";
  import SectionToggle from "@/components/SectionToggle.vue";

  const auth = namespace("auth");

  @Component({
    components: { LzButton, LzTable, DesignModal, LzModal, SectionToggle }
  })
  export default class Read extends Vue {
    @auth.State("id")
    public memberId!: string;

    @auth.State("url")
    public url!: string;

    deleteModalOpen = false;

    projects: {
      id: string;
      title: string;
      skills: VueI18n.TranslateResult;
    }[] = [];

    listFields = [
      { id: "title", label: this.$t("projects.read.table.name") },
      { id: "skills", label: this.$t("projects.read.table.skills") },
      { id: "actions", label: this.$t("projects.read.table.actions") }
    ];

    mounted() {
      this.loadProjects();
    }

    loadProjects() {
      apiProjects.getProjects(this.memberId).then(projects => {
        this.projects = projects.map(project => ({
          id: project.id,
          title: project.title,
          skills: project.skills
        }));
      });
    }

    confirm(): Promise<boolean> {
      this.deleteModalOpen = true;

      return new Promise(res => {
        this.$on("confirm", () => {
          this.deleteModalOpen = false;
          res(true);
        });
        this.$on("cancel", () => {
          this.deleteModalOpen = false;
          res(false);
        });
      });
    }

    async deleteProject({ id }: TProject) {
      // If the user cancels, we don't want to delete the project
      if (!(await this.confirm())) return;

      apiProjects
        .deleteProject(id)
        .then(() => {
          this.$notify({
            type: "success",
            text: this.$tc("projects.create.notifications.projectRemoved")
          });
          this.loadProjects();
        })
        .catch(() => {
          this.$notify({
            type: "error",
            text: this.$tc("projects.create.notifications.projectRemovedError")
          });
        });
    }

    viewProject({ id }: TProject) {
      this.$router.push({
        name: "projectsCreate",
        params: { projectId: id }
      });
    }

    handleToggle() {
      // console.log("toggle");
      // TODO: Implement toggle
      return;
    }
  }
